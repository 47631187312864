import "./Pricing.css";

import { MailchimpFormContainer } from "./MailchimpFormContainer";

function PricingPage() {
  return (
    <div className="Pricing">
      <div className="PricingSection" id="Featured">
        {/* <div className="Highlight">Most Affordable</div> */}
        <div className="PricingHeader">Basic</div>
        <div className="PricingSubheader">
          Weekly newsletter tailored to your family's needs
        </div>
        <div className="PriceSection">
          <div className="DollarAmmount">$20</div>
          <div className="TimeWindow">
            <div>per</div>
            <div>month</div>
          </div>
        </div>
        <a href="https://buy.stripe.com/28o8wF3AJ1nUeQ05km">
          <button className="SubscriptionButton">Subscribe</button>
        </a>
        <div className="Description">
          <div>This includes:</div>
          <ul>
            {/* TODO: Would be nice to have different 
            images for bullet points. Maybe use font-awesome? */}
            <li className="DescriptionBullet">
              Weekly newsletter delivered exactly when you need it based on your
              partner's due date or baby's birthday.
            </li>
            <li className="DescriptionBullet">
              Get actionable tips for your own wellbeing, learn how to support
              your partner, and get fun activities for you and your baby.
            </li>
            <li className="DescriptionBullet">
              Learn how to be a super-dad in a supportive format that is
              tailored towards fathers and their needs.
            </li>
          </ul>
        </div>
      </div>
      <div className="PricingSection">
        {/* <div className="Highlight">User Favorite</div> */}
        <div className="PricingHeader">Premium - Coming Soon</div>
        <div className="PricingSubheader">
          Newsletter, Blog Articles, and Courses
        </div>
        {/* <div className="PriceSection">
          <div className="DollarAmmount">$30</div>
          <div className="TimeWindow">
            <div>per</div>
            <div>month</div>
          </div>
        </div> */}
        <MailchimpFormContainer buttonLabel="Join Waitlist" />
        <div className="Description">
          <div>This includes:</div>
          <ul>
            {/* TODO: Would be nice to have different 
            images for bullet points. Maybe use font-awesome? */}
            <li className="DescriptionBullet">
              Everything included in Basic Tier
            </li>
            <li className="DescriptionBullet">
              Access to our complete library of articles on personal wellness,
              pregnancy support, relationship building, and infant care.
            </li>
            <li className="DescriptionBullet">
              Online courses to build and test your skills in the arena of
              fatherhood.
            </li>
          </ul>
        </div>
      </div>
      <div className="PricingSection">
        {/* <div className="Highlight">Most Features</div> */}
        <div className="PricingHeader">Pro - Coming Soon</div>
        <div className="PricingSubheader">
          Everything you need and a community of dads to support you
        </div>
        {/* <div className="PriceSection">
          <div className="DollarAmmount">$50</div>
          <div className="TimeWindow">
            <div>per</div>
            <div>month</div>
          </div>
        </div> */}
        <MailchimpFormContainer buttonLabel="Join Waitlist" />
        <div className="Description">
          <div>This includes:</div>
          <ul>
            <li className="DescriptionBullet">
              Everything in basic and premium tiers
            </li>
            <li className="DescriptionBullet">
              An online community of dads that offer peer support and ongoing
              mentorship
            </li>
            <li className="DescriptionBullet">
              Discounts on in-person events and courses
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
