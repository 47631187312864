import "./Disclaimers.css";

function Disclaimers() {
  return (
    <div className="Disclaimers">
      <div className="DisclaimerHeader">
        Copyright and Healthcare Disclaimer
      </div>
      <div className="DisclaimerText">
        This web site is provided for educational and informational purposes
        only and does not constitute providing medical advice or professional
        services. The information provided should not be used for diagnosing or
        treating a health problem or disease, and those seeking personal medical
        advice should consult with a licensed physician. Always seek the advice
        of your doctor or other qualified health provider regarding a medical
        condition. Never disregard professional medical advice or delay in
        seeking it because of something you have read on Father Forge's website.
        If you think you may have a medical emergency, call 911 or go to the
        nearest emergency room immediately. No physician-patient relationship is
        created by this web site or its use. Neither the Company nor its
        employees, nor any contributor to this web site, makes any
        representations, express or implied, with respect to the information
        provided herein or to its use.
      </div>
      <div className="DisclaimerText">
        All copyrights to the Father Forge web site and its contents are the
        property of the Father Forge unless otherwise noted. No commercial uses
        are allowed without the prior express permission of Father Forge.
      </div>
    </div>
  );
}

export default Disclaimers;
