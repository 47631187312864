import React, { useState } from "react";
import InputField from "./InputField";

export const CustomForm = ({ status, message, onValidated, buttonLabel }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
  };

  return (
    <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
      {status === "sending" && (
        <div className="mc__alert mc__alert--sending">Sending...</div>
      )}
      {status === "error" && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="mc__field-container">
        <InputField
          onChangeHandler={setFirstName}
          type="text"
          value={firstName}
          className="nameField"
          placeholder="First Name"
          isRequired
        />

        <InputField
          onChangeHandler={setLastName}
          type="text"
          value={lastName}
          placeholder="Last Name"
          isRequired
        />

        <InputField
          onChangeHandler={setEmail}
          type="email"
          value={email}
          placeholder="your@email.com"
          isRequired
        />
      </div>

      <InputField
        label={buttonLabel || "Subscribe"}
        type="submit"
        formValues={[email, firstName, lastName]}
      />
    </form>
  );
};
