import React from "react";
import "./InputField.css";

const InputField = (props) => {
  const validateInput = (values) => {
    if (values.some((f) => f === "") || values[0].indexOf("@") === -1) {
      return true;
    } else {
      return false;
    }
  };

  if (props.type === "submit") {
    return (
      <input
        className="SubscriptionButton"
        type="submit"
        value={props.label}
        disabled={validateInput(props.formValues)}
      />
    );
  } else if (props.type === "textarea") {
    return (
      <textarea
        onChange={(e) => props.onChangeHandler(e.target.value)}
        placeholder={props.placeholder}
        value={props.value}
        required={props.isRequired}
        rows={7}
        name={props.name}
      />
    );
  } else {
    return (
      <input
        onChange={(e) => props.onChangeHandler(e.target.value)}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        required={props.isRequired}
        className="inputField"
        name={props.name}
      />
    );
  }
};

export default React.memo(InputField);
