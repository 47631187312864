import "./Card.css";

import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function SimpleTextCard(props) {
  return (
    <Box className="Box">
      <Card variant="outlined" className="Card">
        <React.Fragment>
          <CardContent>
            <Typography variant="h5" component="div">
              {props.title}
            </Typography>
            <Typography variant="body2">{props.text}</Typography>
          </CardContent>
        </React.Fragment>
      </Card>
    </Box>
  );
}
