import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import { Home } from "./Home";
import PricingPage from "./Pricing";
import Footer from "./Footer";

if (typeof global === "undefined") {
  var global = window;
}

export default function App() {
  return (
    <Router>
      <div>
        <nav>
          <div className="NavBar">
            <Link to="/" className="CompanyName">
              Father Forge
            </Link>
            <div className="Navigation">
              <Link to="/pricing" className="SectionLink">
                Pricing
              </Link>
              {/* <Link to="/blog" className="SectionLink">
                Blog
              </Link> */}
            </div>
          </div>
        </nav>

        <Routes>
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route index element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />
        </Routes>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </Router>
  );
}
