import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { CustomForm } from "./CustomForm.js";

//This was built using this tutorial: https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965
const uValue = "ff7a66a88ed52eaa4e2ffc409";
const idValue = "7e6688fb41";
const postUrl = `https://father-forge.us22.list-manage.com/subscribe/post?u=${uValue}&id=${idValue}`;

export const MailchimpFormContainer = (props) => {
  const buttonLabel = props.buttonLabel;
  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            buttonLabel={buttonLabel}
          />
        )}
      />
    </div>
  );
};
