import "./Footer.css";
import Disclaimers from "./Disclaimers";

function Footer() {
  return (
    <div className="Footer">
      <div className="FooterText">
        <div className="ContactText">
          Let's get in touch! Reach out to us at contact@father-forge.com
        </div>
        <Disclaimers />
      </div>
    </div>
  );
}

export default Footer;
