import "./Home.css";
import { ReactTyped } from "react-typed";
import React from "react";

import PTest from "./assets/pexels-nataliya-vaitkevich-5982105.jpg";
import Baby from "./assets/pexels-iurii-laimin-10276692.jpg";
import Baby2 from "./assets/pexels-anna-shvets-11369233.jpg";
import PricingPage from "./Pricing";
import { MailchimpFormContainer } from "./MailchimpFormContainer";
import SimpleTextCard from "./Card";

export const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <ReactTyped
            showCursor={false}
            strings={[
              "Fatherhood is a journey.",
              "We're with you every step of the way.",
            ]}
            typeSpeed={100}
          />
        </h1>
        <a href="#section-1" className="Button1">
          Find out how
        </a>
      </header>
      <div className="Section1" id="section-1">
        <img
          className="Section1HalfImage"
          src={PTest}
          alt="man and woman looking at pregnancy test"
        />
        <div className="HalfText">
          <h2 className="Section1Header">
            Comprehensive Resources for Every Dad
          </h2>
          <div className="Boxes">
            <SimpleTextCard
              title="Pregnancy Support"
              text="Resources on how to support your partner through every month
                  of pregnancy from conception to childbirth."
            />
            <SimpleTextCard
              title="Newborn Care"
              text="Expert advice on feeding, bathing, diaper changing, and
              understanding your baby's unique needs."
            />
            <SimpleTextCard
              title="Emotional Well-being"
              text="Strategies for managing stress, coping with sleep deprivation,
                and promoting mental health."
            />
            <SimpleTextCard
              title="Relationship Dynamics"
              text="Insights into building strong bonds with your partner,
                co-parenting effectively, and communicating openly."
            />
            <SimpleTextCard
              title="Work-Life Balance"
              text="Practical tips for balancing the demands of work and family,
                setting boundaries, and prioritizing self-care."
            />
            <SimpleTextCard
              title="Milestones and Achievements"
              text="Celebrate the joys and milestones of fatherhood, from the
                first smile to the first steps."
            />
          </div>
          <a href="#section-2" className="Button1">
            Learn more
          </a>
        </div>
      </div>
      <div className="Section2" id="section-2">
        <div className="Section2HalfText">
          <h2 className="Section2Header">
            Tailored Resources for Where You Are
          </h2>
          <div className="Section2Text">
            We know that this process can be incredibly overwhelming. That's why
            we tailor our resources to where you and your family are at in the
            journey. Simply sign up for our newsletter to receive monthly
            articles, videos, and course recommendations based on where your
            partner is in their pregnancy or how old your baby is.
          </div>
          <a href="#section-3" className="Button1" id="section-2-button">
            Tell me more
          </a>
        </div>
        <img
          className="Section2HalfImage"
          src={Baby}
          alt="man in hat holding baby in the air"
        />
      </div>
      <div className="Section3" id="section-3">
        <img className="Section3HalfImage" src={Baby2} alt="man holding baby" />
        <div className="HalfText">
          <h2 className="Section3Header">
            Join the Dad-Focused Learning Platform and Embark on the Journey of
            Fatherhood with Confidence and Support
          </h2>
          <div className="Section3Text">
            <div>
              <strong>Start Your Journey:</strong> Sign up for the platform and
              create your personalized profile.
            </div>
            <div>
              <strong>Explore Resources:</strong> Dive into a vast library of
              articles, videos, and interactive tools designed to support your
              journey as a father.
            </div>
            <div>
              <strong>Join the Community:</strong> Connect with other dads in
              online forums, and virtual workshops to build a network of support
              and shared experiences.
            </div>
          </div>
          <MailchimpFormContainer />
        </div>
      </div>
      <div id="pricing">
        <PricingPage />
      </div>
    </div>
  );
};
